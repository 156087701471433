import React from "react";
import styled from "styled-components";
import Button from "../../Utils/Button";
import ImageTop from "../../Assets/image_top_reseller.webp";
import "../../css/typographyc.css";
const StyledTopResellers = styled.div`
  background-color: var(--dark-back);
  color: white;
  padding: 72px 16px;
  .container {
    background: #252525;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    min-width: 328px;
    min-height: 328px;
    font-family: "Noto_sans_regular";
    font-style: normal;
    padding: 52px 16px;
    h1 {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
    }
    h2 {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-top: 32px;
    }
    .button_blue {
      width: 100%;
      max-width: 438px;
      p {
        font-family: "Noto_sans_regular";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .image_desktop{
        display: none;
    }
  }

  @media (min-width: 1200px) {
    padding: 80px 112px;
    background-color: #1E1E1E;
    .container {
        padding: 40px 60px;
        display: flex;
        justify-content: space-between ;
      h1 {
        font-size: 28px;
        max-width: 360px;
      }
      h2 {
        font-size: 20px;
        max-width: 338px;
      }
      .button_blue{
       p{
        font-size: 16px;
       }
      }
      .image_desktop{
        display: block;
    }
    }
  }
`;
export default function TopResellers() {
  return (
    <StyledTopResellers>
      <div className="container">
        <div>
        <h1>¿Quieres pertenecer a nuestro grupo Top Resellers?</h1>
        <h2>
          Contáctanos para obtener la invitación a todos nuestros eventos.
        </h2>
        <Button
          textButton={"Quiero organizar un evento o ser un Top Reseller"}
          link={"/contacto"}
        />
        </div>
        <div className="image_desktop">
            <img src={ImageTop} alt="" />
        </div>
      </div>
    </StyledTopResellers>
  );
}
