import React from "react";
import styled from "styled-components";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "../../css/typographyc.css";
import Image1 from "../../Assets/slider/slider.webp";
import Image2 from "../../Assets/slider/slider1.webp";
import Image3 from "../../Assets/slider/slider2.webp";
import Image4 from "../../Assets/slider/slider3.webp";
import Image5 from "../../Assets/slider/slider4.webp";
import Image6 from "../../Assets/slider/slider5.webp";
import Image7 from "../../Assets/slider/slider6.webp";

const StyledSlider = styled.div`
  padding: 30px;
  background-color: #1e1e1e;
  h1 {
    font-family: "Noto_sans_regular";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: white;
    text-align: center;
    margin-top: 72px;
    margin-bottom: 60px;
  }
  .sliderimg {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  .alice-carousel {
    img {
      border-radius: 8px;
    }
  }
  .alice-carousel ul li img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .alice-carousel__prev-btn {
    display: none;
  }
  .alice-carousel__next-btn {
    display: none;
  }
  .alice-carousel__dots-item.__active {
    background-color: #0065ff;
  }
  @media (min-width: 1200px) {
    padding: 72px 170px 20px;
    h1{
        font-size: 20px;
        width: 800px;
        margin: 72px auto 60px;
    }
    .alice-carousel ul li img {
    height: 100%;
    max-height: 624px;
    width: 100%;
    object-fit: cover;
  }
  }
`;
export default function Slider() {
  return (
    <StyledSlider id="slider-scroll">
      <h1>
        Eventos co-founded, acompañados de experiencias inolvidables para tus
        clientes. Mostremos las herramientas y tecnologías de su interés.
      </h1>
      <AliceCarousel
        autoPlay
        autoPlayInterval="3000"
        autoPlayDirection="rtl"
        fadeOutAnimation={true}
        mouseTrackingEnabled={true}
        disableAutoPlayOnAction={false}
      >
        <img src={Image1} className="sliderimg" />
        <img src={Image2} className="sliderimg" />
        <img src={Image3} className="sliderimg" />
        <img src={Image4} className="sliderimg" />
        <img src={Image5} className="sliderimg" />
        <img src={Image6} className="sliderimg" />
        <img src={Image7} className="sliderimg" />
      </AliceCarousel>
    </StyledSlider>
  );
}
