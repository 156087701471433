import React from "react";
import Layout from "../components/layout-reseller";
import HomeTitleBack from "../Utils/HomeTitleBack";
import ImgMobile from "../Assets/portada-eventos-mobile.webp";
import ImgDesktop from "../Assets/portada-eventos.webp";
import TopResellers from "../components/eventos/TopResellers";
import Slider from "../components/eventos/Slider";
export default function eventos() {
  return (
    <div>
      <Layout>
        <HomeTitleBack
          display={true}
          imgDesktop={ImgDesktop}
          imgMobile={ImgMobile}
          title={"Es momento de potenciar tus ventas"}
        />
        <Slider />
        <TopResellers />
      </Layout>
    </div>
  );
}
